import React, { useState, useContext } from "react"
import { navigate } from "gatsby"

import { AccountContext } from "../context/account"

const Authenticated = ({ component: Component, location, ...rest }) => {
  const [loading, setLoading] = useState(false)

  const account = useContext(AccountContext)

  console.log(account)

  if (account.isLoggedIn) {
    return (
      <div>
        <div>Logged in</div>
        <div>
          <pre>{JSON.stringify(account, null, 2)}</pre>
        </div>

        <button onClick={() => account.handleLogout()}>Log Out</button>
      </div>
    )
  } else if (!loading && account.session) {
    account
      .session()
      .then(data => {
        setLoading(false)
      })
      .catch(err => {
        if (err.response.status === 401) {
          navigate("/login")
        }
      })
  }

  return "Loading..."
}

export default Authenticated
